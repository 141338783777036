import React, { useEffect, useState } from 'react';
import PageWrapper from '../wrappers/PageWrapper';
import { useNavigate } from 'react-router-dom';
import RoutesPaths from '../../RoutesPaths';
import paymentService from '../../services/payment.service';
import config from '../../config';

// Tmp:
function getSessionData() {
  const localSessionId = localStorage.getItem('session_id');
  const urlParams = new URLSearchParams(window.location.search);
  const urlSessionId = urlParams.get('session_id');
  let sessionId = null;
  let source = '';
  if (localSessionId) {
    sessionId = localSessionId;
    source = 'localStorage';
  } else if (urlSessionId) {
    sessionId = urlSessionId;
    source = 'URL parameter';
  } else {
    source = 'brak';
  }
  console.log('Document domain:', document.domain);
  console.log('Window location:', window.location.href);
  console.log('Session ID:', sessionId);
  console.log('Źródło session ID:', source);
  return sessionId;
}

export const PaymentSummary = () => {
  const [sessionId, setSessionId] = useState();
  const navigate = useNavigate();
  const [timeCounter, setTimeCounter] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState();

  useEffect(() => {
    if (!sessionId) {
      let id = getSessionData();
      // let id = localStorage.getItem('session_id');
      if (!id) {
        console.log('Brak numeru sesji w local storage');
        navigate(RoutesPaths.Home.path);
      }
      setSessionId(id);
    }
    const interval = setInterval(() => {
      setTimeCounter((timeCounter) => timeCounter + 1);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    paymentService.getPaymentStatus({ sessionId }).then((r) => {
      console.log(r);
      setPaymentStatus(r.payment_status);
    });
  }, [timeCounter]);

  return (
    <PageWrapper>
      <div>Payment Summary</div>
      <p>Status płatności:</p>
      <p>session_id: {sessionId}</p>
      <p>Status płatności: {paymentStatus}</p>
      <p>
        {paymentStatus == 10 ? (
          <div>
            <p>Zakonczono</p>
            <a href={`${config.publicUrl}/home`}>{`${config.publicUrl}/home`}</a>
          </div>
        ) : (
          <div>
            <p>Oczekiwnie na płatność</p>
            <div
              className='spinner-border text-primary'
              role='status'
            >
              <span className='visually-hidden'>Oczekiwanie</span>
            </div>
          </div>
        )}
      </p>
    </PageWrapper>
  );
};
