import React, { useState } from 'react';
import TarrifBox from '../../TarrifBox';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import usePopup from '../../../../helpers/usePopup';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
import config from '../../../../config';
import paymentService from '../../../../services/payment.service';

const SetDeviceTarrif = ({ tarrif }) => {
  const [deviceNumber, setDeviceNumber] = useState(tarrif.devices_limit);
  const [isSubscription, setIsubscription] = useState(false);
  const { closePopup } = usePopup();
  const handleSlider = (e) => {
    setDeviceNumber(e.target.value);
  };
  function preventHorizontalKeyboardNavigation(event) {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  }
  const createOrder = (e) => {
    e.preventDefault();

    paymentService
      .createOrder({
        tariff: tarrif.id,
        activeDevicesNo: deviceNumber,
        isSubscription: !isSubscription,
      })

      .then((r) => {
        console.log('response create order');
        console.log(r);
        if (r.p24_token) {
          localStorage.setItem('session_id', r.session_id);
          location.replace(`${config.tariffPayment}${r.p24_token}`);
        }
      });
  };

  return (
    <div className='set-device-list-popup'>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={6}
        >
          <TarrifBox tarrif={tarrif} />
        </Grid>
        <Grid
          item
          xs={6}
          flexDirection='column'
        >
          <p>Liczba urządzeń: {deviceNumber}</p>
          <p>
            <Checkbox
              value={isSubscription}
              onChange={() => setIsubscription(!isSubscription)}
              {...label}
            />
            Jednorazowa transakcja
          </p>

          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            sx={{ width: 200 }}
            marginBottom={6}
            marginTop={20}
          >
            <Slider
              onChange={(e) => handleSlider(e)}
              sx={{
                '& input[type="range"]': {
                  WebkitAppearance: 'slider-horizontal',
                },
              }}
              orientation='horizontal'
              defaultValue={tarrif.devices_limit}
              max={tarrif.devices_limit}
              min={1}
              aria-label='Temperature'
              valueLabelDisplay='auto'
              onKeyDown={preventHorizontalKeyboardNavigation}
            />
          </Box>
        </Grid>
      </Grid>
      <div className='buttons-wrapper'>
        <button
          className='btn'
          onClick={() => closePopup()}
        >
          anuluj
        </button>
        <button
          onClick={(e) => createOrder(e)}
          className='btn'
        >
          Dokonaj płatności
        </button>
      </div>
    </div>
  );
};

export default SetDeviceTarrif;
