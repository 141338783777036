import React from 'react';
import usePopup from '../../helpers/usePopup';
import SetDeviceTarrif from './popup/variants/SetDeviceTarrif';

const TarrifBox = ({ availableSelect, tarrif, isPromote }) => {
  const { initPopup } = usePopup();

  const chooseTariff = (e) => {
    e.preventDefault();
    initPopup(<SetDeviceTarrif tarrif={tarrif} />);
  };

  return (
    <div className={`list-wrapper ${isPromote ? 'promote-list' : ''}`}>
      {isPromote && (
        <div className='promote-title-wrapper'>
          <p>Najlepszy wybór</p>
        </div>
      )}
      <div className='list-title list-title-pricing here'>
        <p className='name'>{tarrif.name_pl}</p>
        <p className='price'>
          {tarrif.net_sum} {tarrif.currency.symbol}
        </p>
        <p className='period'>{tarrif.tarrif_duration} dni</p>
      </div>
      <ul className='offer-list here'>
        <li>
          <p className='key'>value</p>
          <p className='value'>{tarrif.all_messages_limit}</p>
        </li>
        <li>
          <p className='key'>value</p>
          <p className='value'>{tarrif.devices_limit}</p>
        </li>
        <li>
          <p className='key'>value</p>
          <p className='value'>?</p>
        </li>
        <li>
          <p className='key'>value</p>
          <p className='value'>?</p>
        </li>
        <li>
          <p className='key'>value</p>
          <p className='value'>{tarrif.days_in_archive}</p>
        </li>
        <li>
          <p className='key'>value</p>
          <p className='value'>?</p>
        </li>
        <li>
          <p className='key'>value</p>
          <p className='value'>?</p>
        </li>
      </ul>
      <div className='button-wrapper'>
        {availableSelect && (
          <button
            onClick={(e) => chooseTariff(e)}
            className={`buy-button ${isPromote ? 'buy-button-promote' : 'buy-button-no-promote'}`}
          >
            Wybieram
          </button>
        )}
      </div>
    </div>
  );
};

export default TarrifBox;
